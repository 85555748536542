import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import LandingSection from '../components/LandingSection'
import SelectLevelCard from '../components/cards/SelectLevelCard'
import Layout from '../layouts'

import api from '../backend'

import styles from './index.module.scss'
import { navigate } from 'gatsby'

const IndexPage = () => {
  useEffect(() => {
    // reroute to /user if we're logged in (stats fails if not)
    // api.stats()
    //  .then(() => navigate('/user'))
  }, [])

  return (<Layout>
    <div className={styles.landing}>
      <LandingSection />
      <SelectLevelCard />
    </div>
    <div className={`${styles.container} ${styles.landingSectionWhite}`}>
      <div className={styles.row}>
        <div className={styles.colMd6}>
          <h2>Kanji&nbsp;lernen. Auf&nbsp;Deutsch.</h2>
          <h3>Etwa 2300 Kanji und 6000 Vokabeln</h3>
          <p>Alle offiziellen Schulkanji, Joyo-Kanji und JLPT N1 relevanten Kanji.</p>
          <h3>Lesungen mit Beispielen</h3>
          <p>Alle Onyomi und Kunyomi werden mit Beispielwörtern passend zu deinem Level trainiert.</p>
          <h3>Bedeutung mit Radikalen und Übersetzung</h3>
          <p>Beim Lernen werden dir Beispielwörter und Sätze, Übersetzungen und Radikale gezeigt.</p>
        </div>
        <div className={styles.colMd6}>
          <img className={styles.screenshots} src="/style/kanji-trainer-deutsch-cotsu.png" alt="Beispielscreenshots" />
        </div>
      </div>
    </div>
    <Footer />
  </Layout>)
}

export default IndexPage
