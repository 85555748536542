import React from 'react'
import Link from 'gatsby-link'

import styles from './SelectLevelCard.module.scss'

const SelectLevelCard = () => {
  return (<div className={styles.studyContainer}>
    <div className={styles.selectLevelCard}>
      <p>
        <Link to="/meinbereich/" className={styles.btnGhost}>Anmelden</Link>
      </p>
      <p><br/>oder einfach ohne Anmeldung</p>
      <p>
        <Link to="/kanji-lesen/" className={styles.btnGhost}>Kanji lesen</Link>
        <Link to="/kanji-schreiben/" className={styles.btnGhost}>Kanji schreiben</Link>
      </p>
    </div>
  </div>)
}

export default SelectLevelCard
