import React from 'react'

import styles from './LandingSection.module.scss'

const LandingSection = () => {
  return (<div>
    <div className={styles.landingSection}>
      <img className={styles.landingLogo} src="/style/cotsucotsu-logo.png" alt=""/>
      <h1 className={styles.landingHeading}>Kanji-Trainer</h1>
    </div>
  </div>)
}

export default LandingSection
